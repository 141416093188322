export const isPibex = (market) =>
  market && market === "pibex" ? true : false;

export const isOfferPibex = (offer) => {
  if (!offer) {
    return false;
  }

  if(offer.isStockPrice) {
    return true   
  }
  
  if (offer.details?.market) {
    return isPibex(offer.details.market);
  }

  return false;
}