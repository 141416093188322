import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import ElectricityMeterForm from "../components/organisms/electricity-meter-form/ElectricityMeterForm";
import OfferList from "../components/organisms/offers/OfferList";

import calculateOffers from "../helpers/calculatePrice";
import * as constants from "../helpers/constants";
import roundNumber from "../helpers/roundNumber";
import Carousel from "../components/Carousel/BusinessCardsCarousel";

import "./electricity-meter.css";
import UnorderedListTypeSecond from "../components/organisms/lists/UnorderedListTypeSecond";
import useLoaderState from "../hooks/useLoaderState";

const HeadContainer = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Head = styled.h2`
  font-size: 28px;
  line-height: 34px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;

  @media (min-width: 820px) {
    display: none;
  }
`;

const StickyElectricityMeterForm = styled(ElectricityMeterForm)`
  position: "sticky";
  background: tomato;
  top: 0;
`;

const ElectricityMeterPage = ({ location }) => {
  const [state, setState] = useState({
    search: Object.fromEntries(new URLSearchParams(location.search))
  });

  const [isLoading, startOfferLoading, endOffersLoading] = useLoaderState();
  const [isCalculatorTouched, setIsCalculatorTouched] = useState(false);


  const [displayConsumption, setDisplayConsumption] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(state.qs);
    const hasConsumption = Number(state.search.consumption) > 0
    const isOfferTab = Number(state.search.option) == 2
    if ((hasConsumption && !isOfferTab)) setIsCalculatorTouched(true);
    setDisplayConsumption(isOfferTab)
  }, [location.search, state.search]);

  const params = new URLSearchParams(state.qs);
  // Parse query parameters
  const initialDayConsumption = state.search.day;
  const initialNightConsumption = state.search.night;
  const initialMeter = state.search.meter;
  const initialLevel = state.search.level;
  const initialInstalledPower = state.search.instPower;
  const initialConsumptionLv =
    initialDayConsumption && initialNightConsumption
      ? (Number(initialDayConsumption) + Number(initialNightConsumption)) /
        constants.MONEY_TO_KW_MULTIPLIER
      : Number(state.consumptionlv) || 0;

  // TODO: Read the query param consumption first
  const consumptionParam = state.search.consumption;
  const initialConsumption =
    initialDayConsumption && initialNightConsumption
      ? Number(initialDayConsumption) + Number(initialNightConsumption)
      : consumptionParam
      ? consumptionParam
      : roundNumber(initialConsumptionLv * constants.MONEY_TO_KW_MULTIPLIER) ||
        0;
  const initialOperator = state.search.operator || constants.defaultOperator;

  // Set initial data for precalculation and populating form
  const initialData = {
    consumption: roundNumber(initialConsumption),
    consumptionlv: roundNumber(initialConsumptionLv),
    meter: initialMeter || constants.defaultМeter,
    level: initialLevel || constants.defaultLevel,
    day:
      initialDayConsumption ||
      Math.round(initialConsumption * (1 - constants.dayNightProportion)),
    night:
      initialNightConsumption ||
      Math.round(initialConsumption * constants.dayNightProportion),
    instPower: initialInstalledPower || constants.defaultInstalledPower,
    operator: initialOperator,
  };

  const initialDataStatic = {
    consumption: 500,
    meter: constants.defaultМeter,
    level: constants.defaultLevel,
    day: Math.round(500 * (1 - constants.dayNightProportion)),
    night: Math.round(500 * constants.dayNightProportion),
    instPower: constants.defaultInstalledPower,
    operator: initialOperator,
  };

  const onFormSubmit = (searchParams) => {
    const qs = `?${searchParams.toString()}`;

    setState({ search: Object.fromEntries(searchParams) });
    window.history.pushState({ path: {...searchParams} }, "", qs);
  };

  const offers = useStaticQuery(graphql`
    {
      allContentfulDistributorOffer(filter: { node_locale: { eq: "bg" } }) {
        nodes {
          id
          offerName
          slug
          contract {
            filename
          }
          offerConditions {
            ... on ContentfulDistributorOfferCondition {
              title
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          details {
            price
            internal {
              content
            }
            operator
            market
            type
          }
          greenEnergy
          isStockPrice
          specialOffer
          distributor {
            title
            slug
            website
            logo {
              gatsbyImageData(width: 400, height: 400, placeholder: BLURRED)
            }
            contractSigned
          }
        }
      }

      allContentfulLayoutBusinessCardsBusinessCard(
        filter: { node_locale: { eq: "bg" } }
      ) {
        nodes {
          title
          size
          consumption
          icon {
            file {
              url
            }
          }
          iconActive {
            file {
              url
            }
          }
          background
        }
      }
    }
  `);  
  
  // Initial offers calculation
  let cleanOffers = offers.allContentfulDistributorOffer.nodes.filter(
    (offer) => {
      if (offer.details !== null) return true;
      else return false;
    }
  );
  let calculatedOffers = calculateOffers(cleanOffers, initialData);
  let calculatedOffersStatic = calculateOffers(cleanOffers, initialDataStatic);
  let businessCards = offers.allContentfulLayoutBusinessCardsBusinessCard.nodes;
  
  const sortOffers = (firstOffer, secondOffer) => {
    if (firstOffer.contract) {
      if (secondOffer.contract) {
        return firstOffer.totalPrice - secondOffer.totalPrice;
      }

      return -1;
    }
    return firstOffer.totalPrice - secondOffer.totalPrice;
  };

  return (
    <Layout>
      <Seo
        title="Калкулатор | Сравнете доставчици на електроенергия"
        description="Сравнете доставчици на електроенергия и изберете най-изгодния за
вас. Сменете своя доставчик и спестете от цената за ток."
      />
      {isLoading && <Overlay />}
      <section className="background-gray">
        <div className="container vertical--spacing">
          <div className="row">
            <div className="col-md-4">
              {isCalculatorTouched ? (
                <ElectricityMeterForm
                  setIsCalculatorTouched={setIsCalculatorTouched}
                  sticky={true}
                  initialData={initialData}
                  onFormSubmit={onFormSubmit}
                  isCalculatorTouched={isCalculatorTouched}
                  startOfferLoading={startOfferLoading}
                  isLoading={isLoading}
                  location={location}
                  toggleConsumptionDisplay={setDisplayConsumption}
                />
              ) : (
                <HeadContainer>
                  <Head>elca.bg ви спестява разходи за ток и време:</Head>
                  <UnorderedListTypeSecond
                    props={[
                      "Намирате различни оферти",
                      "Сравнявате цени и условия",
                      "Изберете доставчик",
                      "Заявете договор",
                    ]}
                  />
                </HeadContainer>
              )}
            </div>
            <div className="col-md-8">
              {!isCalculatorTouched && (
                <ElectricityMeterForm
                  setIsCalculatorTouched={setIsCalculatorTouched}
                  initialData={initialData}
                  onFormSubmit={onFormSubmit}
                  isCalculatorTouched={isCalculatorTouched}
                  startOfferLoading={startOfferLoading}
                  isLoading={isLoading}
                  location={location}
                  toggleConsumptionDisplay={setDisplayConsumption}
                />
              )}
              {initialData.consumption > 0 ? (
                <OfferList
                  offers={calculatedOffers}
                  consumptionData={initialData}
                  operator={initialOperator}
                  consumption={consumptionParam}
                  sortingFn={sortOffers}
                  endOffersLoading={endOffersLoading}
                  isLoading={isLoading}
                  search={state.search}
                />
              ) : (
                <OfferList
                  offers={calculatedOffersStatic}
                  consumptionData={initialDataStatic}
                  operator={initialOperator}
                  consumption={consumptionParam}
                  sortingFn={sortOffers}
                  endOffersLoading={endOffersLoading}
                  isLoading={isLoading}
                  search={state.search}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ElectricityMeterPage;
